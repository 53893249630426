(function(root, unasigned) {
	this.prototype.zengin = function() {
		var replacements;

		replacements = {
			'ｧ' : 'ｱ',
			'ｨ' : 'ｲ',
			'ｩ' : 'ｳ',
			'ｪ' : 'ｴ',
			'ｫ' : 'ｵ',
			'ｬ' : 'ﾔ',
			'ｭ' : 'ﾕ',
			'ｮ' : 'ﾖ',
			'ｯ' : 'ﾂ',
			'ｰ' : '-',
			'‐' : '-',
			'―' : '-'
		};
		return this.katakana().hankaku().toUpperCase().replace(/[ｧｨｩｪｫｬｭｮｯｰ‐―]/g, function(character) {
			return replacements[character];
		});
	};
}).call(String, this);
(function(root, unasigned) {
	var md;

	root.md = md = root.md || new Object();
	md.namespace = function(name) {
		var parent;

		parent = md;
		name.split(/\./).each(function(name) {
			parent = (parent[name] = parent[name] || new Object());
		});
		return parent;
	};
	md.YearMonth = function(year, month) {
		this.year = year;
		this.month = month;
		this.normalize();
	};
	md.YearMonth.prototype.year = null;
	md.YearMonth.prototype.month = null;
	md.YearMonth.prototype.toDate = function() {
		return new Date(this.year, this.month - 1, 1);
	};
	md.YearMonth.prototype.normalize = function() {
		while (this.month < 1) {
			this.year --;
			this.month += 12;
		}
		while (this.month > 12) {
			this.year ++;
			this.month -= 12;
		}
	};
	md.YearMonth.prototype.add = function(year, month) {
		if (month === unasigned) {
			month = year;
			year = 0;
		}
		return new md.YearMonth(this.year + year, this.month + month);
	};
	md.YearMonth.fromDate = function(date) {
		return date ? new md.YearMonth(date.getFullYear(), date.getMonth() + 1) : null;
	};
	md.YearMonth.prototype.increment = function() {
		return this.add(1);
	};
	md.YearMonth.prototype.decrement = function() {
		return this.add(-1);
	};
	md.BasisPoint = function(value) {
		this.value = value;
	};
	md.BasisPoint.prototype.value = null;
	md.BasisPoint.prototype.toNumber = function() {
		return this.value / 10000.0;
	};
	md.BasisPoint.prototype.add = function(value) {
		return new md.BasisPoint(this.value + value);
	};
	md.BasisPoint.fromNumber = function(value) {
		return new md.BasisPoint(Math.round(value * 10000.0));
	};
	md.BasisPoint.gradientAtCompoundInterestOfRatio = function(start, end, divisor) {
		return md.BasisPoint.fromNumber(Math.exp((Math.log(end.toNumber()) - Math.log(start.toNumber())) / divisor));
	};
	md.DatumAsciiDecoder = function(source) {
		this.index = 0;
		this.source = source.trim();
	};
	md.DatumAsciiDecoder.prototype.table = [
		0, 1, 2, 3, 4, 5, 6, 7, 8, 9, null, null, null, null, null, null,
		null, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
		25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, null, null, null, null, 36,
		37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52,
		53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, -1, -2, -3, -4, null,
	];
	md.DatumAsciiDecoder.prototype.index = null;
	md.DatumAsciiDecoder.prototype.source = null;
	md.DatumAsciiDecoder.prototype.peek = function() {
		var code;

		if (this.index >= this.source.length) return null;
		code = this.source.charCodeAt(this.index);
		if (code < 48 || code > 127) return null;
		return this.table[code - 48];
	};
	md.DatumAsciiDecoder.prototype.read6 = function() {
		var code;

		code = this.peek();
		this.index ++;
		return code;
	};
	md.DatumAsciiDecoder.prototype.read12 = function() {
		var value;

		value = this.read6();
		if (value === null || value < 0) return value;
		value <<= 6;
		value |= this.read6();
		return value;
	};
	md.DatumAsciiDecoder.prototype.read18 = function() {
		var value;

		value = this.read6();
		if (value === null || value < 0) return value;
		value <<= 6;
		value |= this.read6();
		value <<= 6;
		value |= this.read6();
		return value;
	};
	md.DatumAsciiDecoder.prototype.integer6 = function() {
		var value;

		value = this.read6();
		if (value === null || value < 0) return false;
		if (value == 0x20) return null;
		return (value < 0x20) ? value : value - 0x40;
	};
	md.DatumAsciiDecoder.prototype.integer12 = function() {
		var value;

		value = this.read12();
		if (value === null || value < 0) return false;
		if (value == 0x800) return null;
		return (value < 0x800) ? value : value - 0x1000;
	};
	md.DatumAsciiDecoder.prototype.integer18 = function() {
		var value;

		value = this.read18();
		if (value === null || value < 0) return false;
		if (value == 0x20000) return null;
		return (value < 0x20000) ? value : value - 0x40000;
	};
	md.DatumAsciiDecoder.prototype.basis18 = function() {
		var value;

		value = this.integer18();
		if (value === null || value === false) return value;
		return new md.BasisPoint(value);
	};
	md.DatumAsciiDecoder.prototype.character = function() {
		var value;

		value = this.peek();
		if (value === null) return false;
		if (value == -4) return null;
		switch (value & 0x30) {
		case 0x00 :
		case 0x10 :
			this.index ++;
			return (value ^ 0x10) + 0x20;
		case 0x20 :
			return this.read12() & 0x3FF;
		case 0x30 :
			return this.read18() & 0xFFFF;
		}
	};
	md.DatumAsciiDecoder.prototype.character = function() {
		var sequence;
		var value;

		sequence = new Array();
		while (true) {
			value = this.character();
			if (value === false) return false;
			if (value === null) break;
			sequence.push(value);
		}
		return String.fromCharCode.apply(String, sequence);
	};
	md.messages = {
		error_network: '通信エラーが発生しました。しばらくしてから再試行してください。'
	};
	md.error = function(text) {
		root.alert(text);
	};
	md.initialize = function($container) {
		$container.find('a.disabled').each(function() {
			$(this).attr('href', 'javascript:void(0)');
		});
		$container.find('input.js-collapse-trigger').each(function() {
			var $this;

			$this = $(this);
			$('input[name="' + $this.attr('name') + '"]').change(function() {
				$($this.attr('data-target')).collapse($this.is(':checked') ? 'show' : 'hide');
			});
			if ($this.is(':checked')) $($this.attr('data-target')).addClass('in');
		});
		$container.find('input.js-check-on-focus-trigger').each(function() {
			var $this;

			$this = $(this);
			$this.focus(function() {
				$($this.attr('data-target')).prop('checked', true);
			});
		});
		$container.find('input:text[data-input-filter]').each(function() {
			var $this;
			var filters;

			$this = $(this);
			filters = $this.attr('data-input-filter').split(/\s*,\s*/);
			$this.blur(function() {
				var value;

				value = $this.val();
				filters.each(function(filter) {
					value = (value[filter])();
				});
				$this.val(value);
			});
		});
		$container.find('.js-form-validation-date').each(function() {
			var $this;
			var $year;
			var $month;
			var $day;

			$this = $(this);
			$year = $this.find('.js-form-validation-date-year');
			$month = $this.find('.js-form-validation-date-month');
			$day = $this.find('.js-form-validation-date-day');
			$this.find('.js-form-validation-date-year, .js-form-validation-date-month, .js-form-validation-date-day').blur(function() {
				var date;

				if ($year.val().length <= 0 || $month.val().length <= 0 || $day.val().length <= 0) return;
				date = Date.create($year.val() + '-' + $month.val() + '-' + $day.val());
				$year.val(date.getFullYear());
				$month.val(date.getMonth() + 1);
				$day.val(date.getDate());
			});
		});
		$container.find('a.js-form-submit').each(function() {
			var $this;

			$this = $(this);
			$this.click(function() {
				var $form;

				$form = $this.closest('form');
				$form.find('input.js-form-submit-value').val($this.attr('data-js-value'));
				$form.submit();
			});
		});
		$container.find('input:checkbox').each(function() {
			var $this;
			var $hidden;
			var $form;

			$this = $(this);
			$hidden = $this.next();
			if (!$hidden.is(':hidden[name="_' + $this.attr('name') + '"]')) return;
			$form = $this.closest('form');
			if ($form.length < 1) return;
			$form.append($hidden);
		});

		var openSelect = function () {
			var _base = this;
			_base.$target1 = $('.box-o-content .wrapper-identity-text');
			_base.$target2 = $('.box-o-content .wrapper-identity-note');
			_base.$target3 = $('.box-o-select');
			_base.$target4 = $('.box-o-content');
			_base.index = 0;
		};
		openSelect.prototype = {
			open: function(index) {
				var _base = this;
				_base.index = index;
				_base.$target1.find('div:eq(' + index + ')').addClass('is-active');
				_base.$target2.find('div:eq(' + index + ')').addClass('is-active');
				_base.$target3.addClass('is-inactive');
				_base.$target4.addClass('is-active');
				if ($('.wrapper-annotation.is-hidden').length) {
					$('.wrapper-annotation').removeClass('is-hidden');
				}
				_base.filterChecklist();
			},
			filterChecklist: function(){
				var _base = this;
				var $checklist = $('.js-checklist');
				var $checkbox = $checklist.find('.checkbox');
				if (!$checkbox.length) return;
				
				$checkbox.each(function(){
					var hideidnex = +$(this).attr('data-hide-index');
					if (hideidnex === +_base.index) {
						$(this).hide().find('input').attr('disabled', true);
					}
				})
			}
		};
		var formOpen = new openSelect();
		var checkStorage = function(path) {
			var getStr = window.localStorage.getItem(path);
			if (getStr === null) {
				getStr = '';
			}
			return getStr;
		};
		var writeStorage = function(index, path) {
			window.localStorage.setItem(path, index);
			return true;
		};
		var removeStorage = function(path) {
			window.localStorage.removeItem(path);
			return true;
		};
		var setTypeError = function() {
			var $typeError = $('.text-danger');
			if ($typeError.length > 0) {
				var index = checkStorage(window.location.pathname);
				if (index != '') {
					formOpen.open(index);
					removeStorage(window.location.pathname);
				}
			}
			$('form').on('submit', function() {
				writeStorage(formOpen.index, window.location.pathname);
			});
		};
		$container.find('.box-o-select .list-o-links li a').each(function () {
			setTypeError();
			return false;
		});
		$container.find('.box-o-select .list-o-links li a').each(function (indexNum) {
			$(this).click(function () {
				formOpen.open(indexNum);
				$('html,body').animate({scrollTop:0} ,'slow');
			});
		});
		$container.find('.box-o-select .list-o-link-other li a').each(function () {
			setTypeError();
			return false;
		});
		$container.find('.box-o-select .list-o-link-other li a').each(function () {
			var indexNum = $container.find('.wrapper-identity-note div').length-1;
			$(this).click(function () {
				formOpen.open(indexNum);
				$('html,body').animate({scrollTop:0} ,'slow');
			});
		});
		$container.find('.js-text-o-number').each(function() {
			var sepDec = (function(num){
				var str = num.toString();
				var arr = str.split('.');
				// If the number contains decimal
				var integer = arr[0];
				var decimal = arr[1];
				var n = "";
				var cnt = 0;
				for (var i=integer.length-1; i>=0; i--){
					n = integer.charAt(i) + n;
					cnt++;
					if (((cnt % 3) === 0) && (i !== 0)) n = ","+n;
				}
				return n + (decimal ? '.' + decimal : '');
			})(this.innerHTML);
			this.innerHTML = sepDec;
		});
		$(document).find('.login .block-alert').each(function() {
			var $this = $(this);
			$this.prependTo('nav + .container > .inner > .inner-contents');
		});
	};
	$(function() {
		root.md.initialize($('body'));
		checkWindowSize();
		avoidMultipleClick();
		preventMultipleClick();
		avoidMultipleClick10Sec();
	});
	md.service = {
		execute: function(method, request, callback) {
			$.ajax({
				type: 'POST',
				url: '/service/' + method.replace(/\./, '/'),
				contentType: 'application/json',
				data: JSON.stringify(request),
				cache: false,
				dataType: 'json',
				success: callback,
				error: function(request) {
					md.error(md.messages.error_network);
				}
			});
		}
	};
	md.service.postalCodeAddress = {
		getPostalCodeAddressByPostalCode: function(
				postalCode,
				callback) {
			md.service.execute(
					'postalCodeAddress.getPostalCodeAddressByPostalCode',
					{ postalCode: postalCode },
					callback);
		}
	};
	md.modalOpen = function(selector) {
		var $target = selector ? $(selector) : $('.wrapper-modal');
		$('body').css('overflow-y', 'hidden');
		$target.addClass('is-active');
	};
	md.modalClose = function(selector) {
		var $target = selector ? $(selector) : $('.wrapper-modal');
		$('body').css('overflow-y', 'scroll');
		$target.removeClass('is-active');
	};

	$(window).resize(function() {
		checkWindowSize();
	});
	function checkWindowSize() {
		var _width = $(window).width();
		var _whalf = (_width - 774) / 2;
		var _whalf2 = (_width - 700) / 2;
		if (_width >= 774) {
			initElementSize(_whalf+37, _whalf+37);
		} else if (_width >=700 && _width < 774) {
			initElementSize(_whalf2+37, _whalf2+37);
		} else if (_width >320 && _width < 700) {
			initElementSize(37, 37);
		} else {
			initElementSize(10, 10);
		}
	}
	function initElementSize(_w1, _w2){
		var $target = $(".mod-full");
		$target.css({
			"margin-right": -_w1,
			"margin-left": -_w1,
			"padding-right": _w2,
			"padding-left": _w2,
		});
	}

	// Add a className 'J-avoid-multiple-click' to <button> to avoid multiple click.
	// Then it will remove the disabled status after 2 second.
	function avoidMultipleClick() {
		const selector = '.J-avoid-multiple-click';
		const className = 'btn-disable';
		$(document).on('click', selector, function() {
			const $this = $(this);
			$this.addClass(className);
			setTimeout(function() {
				$this.removeClass(className);
			}, 2000);
		});
	}

	function preventMultipleClick() {
		const selector = '.J-prevent-multiple-click';
		const className = 'btn-disable';
		$(document).on('click', selector, function() {
			const $this = $(this);
			$this.addClass(className);
			setTimeout(function() {
				$this.removeClass(className);
			}, 10000);
		});
	}

	// 複数クリック対応の改良版
	// disableを戻す時間を60秒に変更、複数ボタンのdisable化対応
	// Safariでブラウザの戻るボタンで再表示すると、60秒経過しないとenableにならないため時間を10秒に変更
	/**
	 * @deprecated
	 * ボタン１つの時と複数の時の関数が混ざっていて、バグあり
	 */
	function avoidMultipleClick10Sec() {
		const selector = '.J-avoid-multiple-click-10s';
		const className = 'btn-disable';
		$(document).on('click', selector, function() {
			const $this = $(this);
			$this.addClass(className);
			setTimeout(function() {
				$this.removeClass(className);
			}, 10000);

			$(document).find(selector).map(function() {
				const $that = $(this);
				$that.addClass(className);
				setTimeout(function() {
					$that.removeClass(className);
			  	}, 10000);
			});
		});
  }
})(this);
$(function() {
	//Press Enter to not submit if form having .preventSubmitEnter
	$("input,select",".preventSubmitEnter").keypress(function(event) {
		if ((event.keyCode && event.keyCode === 13)) {
			return false;
		}
	});
});

// REMOVE ME. For 20170228.
window.foo = 1;
